.Button {
  box-sizing: border-box;
  border-radius: 9px;
  padding: 13px;
  text-decoration: none;
  min-width: 20%;
  transition: all 0.7s ease-in-out;
  font-weight: 300;

  &:focus {
    outline: none;
  }

  &--invisible {
    visibility: hidden;
  }

  &--visible {
    visibility: visible;
  }

  &--brand {
    background-color: $brand-color;
    color: $brand-color-dark;
    border: 1px solid #6f9656;

    &:hover {
      color: $brand-text-color;
      background-color: #6f9656;
    }

    &--dark {
      background-color: $brand-color-dark;
      color: $brand-color;
      border: 1px solid #303044;

      &:hover {
        background-color: #303044;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #6b994c !important;

    &:hover {
      color: $brand-color-dark;
    }
  }
}
