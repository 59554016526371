.modal {
  overflow: auto !important;
  padding-left: 6%;
  padding-right: 6%;

  &--visible {
    display: block !important;
  }

  &--invisible {
    display: none;
  }

  &-backdrop {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1050;
    position: fixed;
    height: 100%;
    width: 100%;
    display: block;
    background-color: #00000080 !important;
    animation: modalBackdrop 0.5s 0s ease-in-out;
  }

  &-box {
    border-radius: 6px;
    padding: 30px;
    margin: auto;
    margin-top: 120px;
    z-index: 1100;
    position: relative;
    width: 100%;
    animation: modalBox 0.6s 1 0s linear;

    &--small {
      max-width: 400px;
    }

    &--medium {
      max-width: 600px;
    }

    &--large {
      max-width: 800px;
    }
  }

  &-close {
    text-align: right;
    cursor: pointer;
    margin-bottom: 10px;
  }

  &-theme {
    &--white {
      background-color: #fff;
    }

    &--gradient {
      background-size: cover;
      background: linear-gradient(180deg, #12391fdb 0%, #000000e6 100%);
    }
  }
}

@keyframes modalBackdrop {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modalBox {
  0% {
    transform: translateY(-300px);
  }
  25% {
    transform: translateY(-200px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
