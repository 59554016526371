.Home {
  &__nav {
    position: fixed;
    right: 40px;
    bottom: 30px;
    z-index: 7;

    &__item {
      display: block;
      height: 30px;
      width: 30px;
      border-radius: 100%;
      margin-bottom: 20px;
      background-color: #fff;
      cursor: pointer;

      &--active {
        border: 5px solid $brand-color;
        padding: 13px;
        background-color: transparent;

        & .Home__nav__item--inner {
          display: block;
        }
      }

      &--inner {
        display: none;
        height: 15px;
        width: 15px;
        border-radius: 100%;
        background-color: #fff;
        margin: -8px -7px;
      }
    }
  }

  & header {
    max-height: 100%;
    max-width: 100%;
    position: relative;
  }

  &__header {
    display: flex;
    min-height: 800px;

    &__darkbg {
      background-color: $brand-color-dark;
      width: 50%;
    }

    &__img {
      width: 50%;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    &__overlay {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      color: $brand-text-color;
      background: linear-gradient(90.3deg, #23233275 -6.22%, #232332d9 67.49%);
    }

    &__logo {
      margin: auto;
      max-width: 1400px;
      padding: 30px;
      padding-left: 50px;
    }

    &__content {
      margin: auto;
      margin-top: 150px;
      max-width: 1400px;
      padding: 30px;
      padding-left: 50px;

      & h1 {
        max-width: 500px;
      }

      & p {
        max-width: 570px;
        line-height: 2rem;
      }

      & button {
        min-width: 15%;
        padding-top: 15px;
      }
    }
  }

  &__whoAreWe {
    background-color: $brand-cool-pink;
    padding: 50px;
    padding-top: 100px;
    color: $brand-text-color;

    &__inner {
      margin: auto;
      max-width: 1300px;
    }

    & h1 {
      & span {
        margin: -10px;
        margin-left: 50px;
        display: block;
      }
    }

    & p {
      line-height: 2rem;
      margin-top: 50px;
      max-width: 1000px;
    }

    &__services {
      margin: auto;
      margin-top: 70px;
      max-width: 1200px;

      & > h3 {
        margin-bottom: 70px !important;
      }

      &__item {
        display: flex;
        margin-bottom: 60px;
        cursor: pointer;
        transition: all 0.8s ease-in-out;

        &:hover {
          transform: translate(40px) scale(1.3);
        }

        &__icon {
          padding: 20px;
          border-radius: 8px;
          min-width: 15px;

          & svg {
            width: 50px;
            height: 50px;
            max-width: 100%;
          }
        }

        &__text {
          margin-top: 35px;
          margin-left: 10px;
        }
      }
    }
  }

  &__whyUs {
    background-color: $brand-color-dark;
    padding: 50px;

    &__inner {
      margin: auto;
      max-width: 1300px;

      & h1 {
        padding: 70px 0;
        color: $brand-text-color;
      }
    }

    &__items {
      max-width: 800px;
      margin: auto;
    }

    &__item {
      display: flex;
      margin-bottom: 30px;

      & svg {
        width: 80px;
      }

      &__content {
        margin-left: 20px;
        color: $brand-text-color;

        & p {
          line-height: 2rem;
          max-width: 250px;
        }
      }
    }
  }

  &__call2act {
    max-width: 1300px;
    margin: auto;
    box-shadow: 10px 10px 15px rgba(238, 255, 226, 0.2);
    border-radius: 20px;
    background-color: $brand-color;
    display: flex;
    position: relative;

    & svg {
      max-width: 100%;
      margin-bottom: -45px;
      order: 1;
      width: 94%;
      right: -24px;
      bottom: 9px;
      position: absolute;
      z-index: 1;
    }

    &__content {
      order: 0;
      width: 100%;
      padding: 70px;
      z-index: 7;
      color: $brand-text-color;

      & h1 {
        padding: 0px;
      }

      & p {
        line-height: 2rem;
        max-width: 400px;
      }
    }
  }

  &__footer {
    padding: 50px;
    padding-bottom: 0px;
    display: flex;
    flex-wrap: wrap;

    &__links {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & a {
        color: $brand-text-color;
        margin: 20px;
        margin-top: 10px;
      }
    }
  }

  &__started {
    color: $brand-color-dark;

    & form {
      margin-top: 30px;
    }

    &__btn {
      max-width: 100%;
      padding: 10px;
      padding-top: 30px;

      & button {
        min-width: 100%;
      }
    }

    &__complete {
      & svg {
        margin: 50px auto;
        width: 100%;
      }

      & h3 {
        color: $brand-color;
        margin-bottom: 20px;
      }

      & p {
        line-height: 2rem;
      }

      & button {
        min-width: 100%;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: $breakpoint-desktop) {
  .Home {
    &__call2act {
      & svg {
        bottom: -46px;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-md) {
  .Home {
    &__call2act {
      & svg {
        bottom: -79px;
      }
    }
  }
}

@media (max-width: $breakpoint-bs-sm) {
  .Home {
    &__whoAreWe {
      &__services {
        & > .row {
          margin-bottom: 50px;
          max-height: 500px;
          overflow: auto;
        }
      }
    }

    &__call2act {
      & svg {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpoint-small-screens) {
  .Home {
    &__nav {
      right: 12px;
    }

    &__header {
      &__logo {
        padding: 30px;
      }

      &__content {
        padding: 30px;
        margin-top: 100px;

        & button {
          min-width: 50%;
        }
      }
    }

    &__whoAreWe {
      padding: 30px;
      padding-top: 100px;

      & h1 {
        & span {
          margin: 0;
        }
      }
    }

    &__whyUs {
      padding: 30px;
    }

    &__call2act {
      &__content {
        padding: 30px;

        & button {
          min-width: 50%;
        }
      }
    }

    &__footer {
      padding: 50px 20px;

      & svg {
        margin: auto;
      }

      &__links {
        margin: auto;
        margin-top: 30px;
        text-align: center;

        & a {
          padding: 20px;
          margin: auto;
        }
      }
    }
  }
}
