// FONT FAMILY **/
$font-family-base: "Gilroy", "Helvetica Neue", "Roboto", sans-serif;

// BREAKPOINTS **/
$breakpoint-tiny-screens: 375px;
$breakpoint-small-screens: 450px;
$breakpoint-tablet: 600px;
$breakpoint-desktop: 1000px;
$breakpoint-bs-sm: 576px;
$breakpoint-bs-md: 768px;
$breakpoint-bs-lg: 992px;
$breakpoint-bs-xl: 1200px;
$breakpoint-header: 850px;

// COLORS **/
$brand-color: #8EBE6E;
$brand-color-dark: #232332;
$brand-color-darker: #063768;
$brand-cool-pink: #BE6E8E;
$brand-color-yellow: #F9AA33;
$brand-header-color: #4f4f4f;
$brand-text-color: #EEFFE2;
$brand-input-placeholder: #979EA2;
$brand-input-disabled: #c4c4c446;
$brand-input-border: #B6BCBF;
$brand-lemon: #9dcb3b;
$brand-lemon-lighter: #E7F5E2;
$color-success: #41b661;
$color-danger: #b80202;

$grey: #4a4a4a;
$grey-light: #AAA9A9;
$grey-medium: #4a4a4a80;